<template>

  <body data-layout="horizontal" data-topbar="dark" v-if="showNav">
    <div id="layout-wrapper">
      <!-- Cabeçalho -->
      <header class="ishorizontal-topbar">
        <div class="navbar-header">
          <div class="d-flex">
            <!-- Logo -->
            <div class="navbar-brand-box">
              <a href="/" class="logo logo-light">
                <img :src="loginImage" alt="logo" height="60" class="auth-logo-dark me-start" name="login-img1">
              </a>
            </div>
          </div>
           <!-- Barra icons lado direito -->
           <div class="d-flex">
                        <div class="dropdown">
                            
                            <!-- Lupa -->
                            <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="eva eva-search-outline icon nav-icon"><g data-name="Layer 2"><g data-name="search"><rect width="24" height="24" opacity="0"></rect><path d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"></path></g></g></svg>
                            </button>
                            <!-- End Lupa -->
                            
                            <!-- Quando clicamos na lupa -->
                            <div class="dropdown-menu dropdown-menu-end dropdown-menu-md p-0">
                                <form class="p-2" method="get" action="">
                                    
                                    <div class="search-box">
                                        <div class="position-relative">
                                            <input type="text" class="form-control bg-light border-0" placeholder="Pesquisar..." name="search_query">
                                            <i class="search-icon" data-eva="search-outline" data-eva-height="26" data-eva-width="26"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- End Quando clicamos na lupa -->


                        </div>

                        <!-- Perfil -->
                        <div class="dropdown d-inline-block" >
                            <button type="button" class="btn header-item user text-start d-flex align-items-center" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="eva eva-person icon nav-icon rounded-circle header-profile-user"><g data-name="Layer 2"><g data-name="person"><rect width="24" height="24" opacity="0"></rect><path d="M12 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4z"></path><path d="M18 21a1 1 0 0 0 1-1 7 7 0 0 0-14 0 1 1 0 0 0 1 1z"></path></g></g></svg>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end pt-0">
                                <div class="p-3 border-bottom">
                                    <h6 class="mb-0"></h6>
                                    <p class="mb-0 font-size-11 text-muted"></p>
                                </div>
                                <a class="dropdown-item" @click.prevent="goToProfile"><i class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span class="align-middle" @click="goToProfile">Meu Perfil</span></a>
                                
                                <a class="dropdown-item" href="https://api.whatsapp.com/send/?phone=351933017767&text&type=phone_number&app_absent=0" target="blanck"><i class="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i> <span class="align-middle">Helpdesk</span></a>
                                <div class="dropdown-divider"></div>

                                <!-- Link de Logout -->
                                <a class="dropdown-item" @click.prevent="handleLogout">
                                  <i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>
                                  <span class="align-middle">Logout</span>
                                </a>

                            </div>
                        </div>
                        <!-- End Perfil -->
            </div>
            <!-- End Barra icons lado direito -->
        </div>
        
        <!-- Menu -->
        <div class="topnav">
          <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
              <div class="collapse navbar-collapse" id="topnav-menu-content">
                <!-- Slot para o menu -->
                <slot name="menu">
                  <ul class="navbar-nav">

                    <li class="nav-item" id="dashboard">
                    <router-link 
                      to="/dashboard" 
                      class="nav-link dropdown-toggle arrow-none" 
                      id="topnav-dashboard" 
                      role="button" 
                      aria-haspopup="true" 
                      aria-expanded="false"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="eva eva-grid-outline icon nav-icon">
                        <g data-name="Layer 2">
                          <g data-name="grid">
                            <rect width="24" height="24" opacity="0"></rect>
                            <path d="M9 3H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM5 9V5h4v4z"></path>
                            <path d="M19 3h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-4 6V5h4v4z"></path>
                            <path d="M9 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zm-4 6v-4h4v4z"></path>
                            <path d="M19 13h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zm-4 6v-4h4v4z"></path>
                          </g>
                        </g>
                      </svg> 
                      <span data-key="t-dashboards">Dashboard</span>
                    </router-link>
                    </li>

                    <li class="nav-item" id="calendar">
                    <router-link 
                      to="/calendar" 
                      class="nav-link dropdown-toggle arrow-none" 
                      id="topnav-dashboard" 
                      role="button" 
                      aria-haspopup="true" 
                      aria-expanded="false"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="eva eva-calendar-outline icon nav-icon"><g data-name="Layer 2"><g data-name="calendar"><rect width="24" height="24" opacity="0"></rect><path d="M18 4h-1V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zM6 6h1v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h1a1 1 0 0 1 1 1v4H5V7a1 1 0 0 1 1-1zm12 14H6a1 1 0 0 1-1-1v-6h14v6a1 1 0 0 1-1 1z"></path><circle cx="8" cy="16" r="1"></circle><path d="M16 15h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z"></path></g></g></svg>
                      <span data-key="t-dashboards">Publicações</span>
                    </router-link>
                    </li>

                    <li class="nav-item" id="leads">
                    <router-link 
                      to="/leads" 
                      class="nav-link dropdown-toggle arrow-none" 
                      id="topnav-dashboard" 
                      role="button" 
                      aria-haspopup="true" 
                      aria-expanded="false"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="eva eva-person-done icon nav-icon"><g data-name="Layer 2"><g data-name="person-done"><rect width="24" height="24" opacity="0"></rect><path d="M21.66 4.25a1 1 0 0 0-1.41.09l-1.87 2.15-.63-.71a1 1 0 0 0-1.5 1.33l1.39 1.56a1 1 0 0 0 .75.33 1 1 0 0 0 .74-.34l2.61-3a1 1 0 0 0-.08-1.41z"></path><path d="M10 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4z"></path><path d="M16 21a1 1 0 0 0 1-1 7 7 0 0 0-14 0 1 1 0 0 0 1 1"></path></g></g></svg>
                      <span data-key="t-dashboards">Leads</span>
                    </router-link>
                    </li>

                    <li class="nav-item" id="invoices">
                    <router-link 
                      to="/invoices" 
                      class="nav-link dropdown-toggle arrow-none" 
                      id="topnav-dashboard" 
                      role="button" 
                      aria-haspopup="true" 
                      aria-expanded="false"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="eva eva-file-text-outline icon nav-icon"><g data-name="Layer 2"><g data-name="file-text"><rect width="24" height="24" opacity="0"></rect><path d="M15 16H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2z"></path><path d="M9 14h3a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2z"></path><path d="M19.74 8.33l-5.44-6a1 1 0 0 0-.74-.33h-7A2.53 2.53 0 0 0 4 4.5v15A2.53 2.53 0 0 0 6.56 22h10.88A2.53 2.53 0 0 0 20 19.5V9a1 1 0 0 0-.26-.67zM14 5l2.74 3h-2a.79.79 0 0 1-.74-.85zm3.44 15H6.56a.53.53 0 0 1-.56-.5v-15a.53.53 0 0 1 .56-.5H12v3.15A2.79 2.79 0 0 0 14.71 10H18v9.5a.53.53 0 0 1-.56.5z"></path></g></g></svg>
                      <span data-key="t-dashboards">Faturas</span>
                    </router-link>
                    </li>
                  </ul>
                </slot>
              </div>
            </nav>
          </div>
        </div>
        <!-- Fim do Menu -->
      </header>

      <!-- Conteúdo da Página -->
      <div class="main-content" style="min-height: 10vh;">
        <div class="page-content">
          <div class="container-fluid">
             <!-- Aqui Página -->
             <router-view></router-view>
            <!-- Fim Aqui Página -->
          </div>
        </div>
      </div>

      <!-- Rodapé -->
      <footer class="footer" style="display: block;">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              All Rights Reserved and Developed by
              <a href="https://typingsolutions.pt/" target="_blank">Typing Solutions</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </body>

  <!-- Apenas exibe o conteúdo do router-view diretamente, sem cabeçalho/rodapé, nas rotas de login e OTP -->
  <router-view v-else>
  
  </router-view>

</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'App',
  props: {
    loginImage: {
      type: String,
      default: require('/src/assets/images/logo-white.png')
    }
  },
  methods: {
    goToProfile() {
    console.log("Profile")
    this.$router.push({ 
            name: 'Profile'
            
           }); 
  },
  },
  computed: {
    showNav() {
      // Verifica se a rota atual não é "/login" ou "/otp"
      return this.$route.path !== "/login" && this.$route.path !== "/otp" && this.$route.path !== "/";
    }
  },
  setup() {
    const router = useRouter();

    // Função de logout
    function handleLogout() {
      // Remove o token de autenticação do localStorage
      localStorage.removeItem('token');
      
      // Redireciona para a página de login
      router.push({ name: 'LoginView' });
    }

    return {
      handleLogout
    };
  }
  
};
</script>

<style>
.topnav .navbar-nav .nav-link .router-link  {
  font-size: 14.4px;
  position: relative;
  padding: 1rem 1.3rem;
  color: #7b8190;
  font-weight: 400;
}

.main-content {
  min-height: 10vh;
  background-color: var(--bs-body-bg);
}

.footer {
  display: block;
  bottom: 0;
  padding: 10px calc(1.5rem / 2);
  position: absolute;
  left: 0;
  color: #74788d;
  right: 255px;
  height: 60px;
  background-color: var(--bs-secondary-bg);
  box-shadow: 0 0px 4px rgba(15, 34, 58, 0.12);
  text-align: center;
}

</style>

import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeView', // Nome atualizado para ser multi-palavra
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'LoginView', // Nome atualizado para ser multi-palavra
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/otp',
    name: 'Otp',
    component: () => import('../views/OtpView.vue'),
    meta: { requiresAuth: true } // Esta rota requer autenticação
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/DashboardView.vue'),
    meta: { requiresAuth: true } // Esta rota requer autenticação
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('../views/CalendarView.vue'),
    meta: { requiresAuth: true } // Esta rota requer autenticação
  },
  {
    path: '/leads',
    name: 'Leads',
    component: () => import('../views/LeadsView.vue'),
    meta: { requiresAuth: true } // Esta rota requer autenticação
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('../views/InvoicesView.vue'),
    meta: { requiresAuth: true } // Esta rota requer autenticação
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/ProfileView.vue'),
    meta: { requiresAuth: true } // Esta rota requer autenticação
  },
  {
    path: '/post/:postId',
    name: 'PostView',
    component: () => import('../views/PostView.vue'),
    meta: { requiresAuth: true } // Esta rota requer autenticação
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Função para verificar se o usuário está autenticado
function isAuthenticated() {
  // Verifica se existe um token no localStorage, indicando que o usuário está autenticado
  return !!localStorage.getItem('token'); // Exemplo simples usando localStorage
}

// Guarda global antes de cada navegação
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Verifica se a rota requer autenticação e se o usuário está autenticado
    if (!isAuthenticated()) {
      // Redireciona para a página de login, usando o nome correto da rota "LoginView"
      next({ name: 'LoginView' });
    } else {
      // Se está autenticado, permite a navegação
      next();
    }
  } else {
    // Se a rota não requer autenticação, permite a navegação
    next();
  }
});

export default router;

/* global eva MetisMenu bootstrap */

(function() {
    "use strict";
  
    // Contador animado
    function initializeCounter() {
      const counters = document.querySelectorAll(".counter-value");
      counters.forEach(counter => {
        const updateCounter = () => {
          const target = +counter.getAttribute("data-target");
          const count = +counter.innerText;
          const increment = target / 250;
          if (count < target) {
            counter.innerText = (count + increment).toFixed(0);
            setTimeout(updateCounter, 1);
          } else {
            counter.innerText = target;
          }
        };
        updateCounter();
      });
    }
  
    // Ativar item ativo no menu
    function activateMenuItem() {
      const menuItems = document.querySelectorAll("#sidebar-menu a");
      const currentUrl = window.location.href.split(/[?#]/)[0];
      menuItems.forEach(item => {
        if (item.href === currentUrl) {
          item.classList.add("active");
          let parent = item.parentElement;
          while (parent && parent.id !== "sidebar-menu") {
            parent.classList.add("mm-active", "mm-show");
            parent = parent.parentElement;
          }
        }
      });
    }
  
    // Configuração do layout
    function configureLayout() {
      const body = document.body;
      const sidebarSetting = document.getElementById("sidebar-setting");
      if (body.getAttribute("data-layout") === "horizontal") {
        if (sidebarSetting) sidebarSetting.style.display = "none";
        body.removeAttribute("data-sidebar");
      } else if (sidebarSetting) {
        sidebarSetting.style.display = "block";
      }
    }
  
    // Controle de rolagem para o topo fixo
    function handleScroll() {
      const topbar = document.getElementById("page-topbar");
      if (topbar) {
        const scrollPosition = window.scrollY;
        if (scrollPosition >= 30) {
          topbar.classList.add("sticky");
        } else {
          topbar.classList.remove("sticky");
        }
      }
    }
  
    // Inicializar tooltips e popovers
    function initializeBootstrapPlugins() {
      document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
        if (bootstrap.Tooltip) new bootstrap.Tooltip(el);
      });
      document.querySelectorAll('[data-bs-toggle="popover"]').forEach(el => {
        if (bootstrap.Popover) new bootstrap.Popover(el);
      });
      document.querySelectorAll(".toast").forEach(el => {
        if (bootstrap.Toast) new bootstrap.Toast(el);
      });
    }
  
    // Aguardar carregamento do DOM antes de inicializar
    document.addEventListener("DOMContentLoaded", function() {
      console.log("Página carregada e DOM pronto.");
  
      const preloader = document.getElementById("preloader");
      if (preloader) {
        preloader.style.display = "none";
      }
  
      // Verifica se eva e MetisMenu estão carregados
      if (eva) eva.replace();
      if (typeof MetisMenu !== "undefined") new MetisMenu("#side-menu");
  
      initializeCounter();
      activateMenuItem();
      configureLayout();
  
      // Alternar entre modo escuro e claro
      document.querySelectorAll("input[name='layout-mode']").forEach(input => {
        input.addEventListener("change", event => {
          if (event.target.value === "light") {
            document.body.setAttribute("data-bs-theme", "light");
            document.body.setAttribute("data-topbar", "light");
          } else {
            document.body.setAttribute("data-bs-theme", "dark");
            document.body.setAttribute("data-topbar", "dark");
          }
        });
      });
  
      // Inicializar plugins do Bootstrap
      initializeBootstrapPlugins();
  
      console.log("Script principal inicializado com sucesso.");
    });
  
    // Adiciona o listener de rolagem
    window.addEventListener("scroll", handleScroll);
  })();
  
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/icons.min.css'
import '@/assets/css/app.css'

import '@/assets/js/app.js'

createApp(App).use(router).mount('#app')
